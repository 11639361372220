<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane :key="1" tab="可视化"/>
        <a-tab-pane :key="2" tab="常规报表"/>
      </a-tabs>
      <a-card :bordered="false" v-show="activeKey===1">
        <a-row>
          <a-col :span="12">
            <div style="padding: 2px">
              <div class="two" style="float: left;" >
                <span :class="isSalesSelect === '1'?'isActive': ''" @click="handelSalesClick('1')" style="font-size: 14px">昨日</span>
                <span :class="isSalesSelect === '7'?'isActive': ''" @click="handelSalesClick('7')" style="font-size: 14px">近7日</span>
                <span :class="isSalesSelect === '30'?'isActive': ''" @click="handelSalesClick('30')" style="margin-right:10px; font-size: 14px">近30日</span>
              </div>
              <a-range-picker valueFormat="YYYY-MM-DD"  @change="salesDateChange"  size="default"/>
            </div>
            <div id="salesChart" style="height: 600px"></div>
          </a-col>
          <a-col :span="12">
            <div style="padding: 5px">
              <div class="two"  style="float: left;">
                <span :class="isCartSelect === '1'?'isActive': ''" @click="handelCartClick('1')">昨日</span>
                <span :class="isCartSelect === '7'?'isActive': ''" @click="handelCartClick('7')">近7日</span>
                <span :class="isCartSelect === '30'?'isActive': ''" @click="handelCartClick('30')" style="margin-right:10px;">近30日</span>
              </div>
              <a-range-picker  valueFormat="YYYY-MM-DD"  @change="cartDateChange"  size="default"/>
            </div>
            <div id="cartChart" style="height: 600px"></div>
          </a-col>
        </a-row>
      </a-card>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {tableMixin} from '@/store/table-mixin'
import * as echarts from 'echarts'
import {goodsCart, goodsSales} from "@/api/statistics/goods";
import moment from 'moment';

export default {
  name: 'goodsSta',
  components: {},
  mixins: [tableMixin],
  data() {
    return {
      activeKey: 1,
      salesParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
        sortField: 'salesNum',
        sortOrder: 'desc',
        pageNum: 1,
        pageSize: 10,
      },
      cartParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
        sortField: 'salesNum',
        sortOrder: 'desc',
        pageNum: 1,
        pageSize: 10,

      },
      /*销售数据选择*/
      isSalesSelect: undefined,
      isCartSelect: undefined,
    }
  },
  filters: {},
  created() {
  },
  mounted() {
    this.drawSalesChart()
    this.drawCartChart()

  },
  computed: {},
  watch: {},
  methods: {
    moment,
    changeTab(index) {
      if (index == null) {
        index = undefined;
      }
      this.activeKey = index;
    },

    subMonth(num) {
      const newDate = new Date();
      newDate.setMonth(newDate.getMonth() - num);
      return newDate;
    },
    subDay(num) {
      return moment().add(-num, 'days');
    },

    salesDateChange(val) {
      this.salesParam.startTime = val[0] + ' 00:00:00';
      this.salesParam.endTime = val[1] + ' 23:59:59';
      this.drawSalesChart();
    },
    cartDateChange(val) {
      this.cartParam.startTime = val[0] + ' 00:00:00';
      this.cartParam.endTime = val[1]+ ' 23:59:59';
      this.drawCartChart();
    },
    handelSalesClick(val) {
      this.isSalesSelect = val;
      this.salesParam.chartType = '1';
      this.salesParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.salesParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawSalesChart();
    },
    handelCartClick(val) {
      this.isCartSelect = val;
      this.cartParam.chartType = '1';
      this.cartParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.cartParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawCartChart();
    },
    drawSalesChart() {
      // // 添加
      var chartDom = document.getElementById("salesChart");
      var myChart = echarts.init(chartDom);
      var option;
      goodsSales(this.salesParam).then((res) => {
        if (res.code === "20001") {
          //y轴数据, 商品名称
          let yaxis = res.data.yyAxis;
          let xAxis = res.data.xxAxis;
          option = {
            title: {
              text: '商品销量排行榜'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {},
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              data: yaxis
            },
            series: [
              {
                name: '商品销量',
                type: 'bar',
                data: xAxis
              },
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },
    drawCartChart() {
      // // 添加
      var chartDom = document.getElementById("cartChart");
      var myChart = echarts.init(chartDom);
      var option;
      goodsCart(this.salesParam).then((res) => {
        if (res.code === "20001") {
          //y轴数据, 商品名称
          let yaxis = res.data.yyAxis;
          let xAxis = res.data.xxAxis;
          option = {
            title: {
              text: '商品加购量排行榜'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {},
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              data: yaxis
            },
            series: [
              {
                name: '商品加购量',
                type: 'bar',
                data: xAxis
              },
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },
  }
}
</script>
<style lang="less" scoped>
.two {
  margin-left: 10px;
  margin-right: 10px;

  span {
    display: inline-block;
    line-height: 30px;
    width: 60px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #999999;
    // color: #1890ff;
  }

  span:nth-child(1) {
    border-radius: 6px 0 0 6px;
    border-right: none;

  }

  span:nth-child(2) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(3) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(4) {
    border-radius: 0 6px 6px 0;
    position: relative;
  }

  span:nth-child(4) .el-date-editor {
    position: absolute; //绝对定位
    top: 0;
    left: 0;
    opacity: 0; //设置完全透明
  }
}

.isActive {
  background: #1890ff;
  color: #ffffff !important;
  border: 1px solid #1890ff !important;
}

</style>
